import posthogJs, { type PostHog } from 'posthog-js'

import { config } from './constants/config'

let posthog: PostHog | undefined

if (config.posthog.key)
	posthog = posthogJs.init(config.posthog.key, {
		api_host: config.posthog.host,
		person_profiles: 'always',
	})

export { posthog }
