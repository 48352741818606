const {
	NODE_ENV,
	VITE_SENTRY_AUTH_TOKEN,
	VITE_SENTRY_ORG,
	VITE_SENTRY_PROJECT,
	VITE_SENTRY_DSN,
	VITE_SUPABASE_URL,
	VITE_SUPABASE_KEY,
	VITE_SUPABASE_BUCKET,
	VITE_POSTHOG_KEY,
	VITE_POSTHOG_HOST,
	VITE_APPOINTMENT_WEBHOOK_URL,
	VITE_RESUME_WEBHOOK_URL,
} = import.meta.env

export const config =
	NODE_ENV === 'development'
		? {
				environment: NODE_ENV,
				sentry: {
					authToken: null,
					org: null,
					project: null,
					dsn: null,
				},
				supabase: {
					url: null,
					key: null,
					bucket: null,
				},
				posthog: {
					key: null,
					host: 'https://us.i.posthog.com',
				},
				webhook: {
					appointment: null,
					resume: null,
				},
			}
		: {
				environment: NODE_ENV,
				sentry: {
					authToken: VITE_SENTRY_AUTH_TOKEN,
					org: VITE_SENTRY_ORG,
					project: VITE_SENTRY_PROJECT,
					dsn: VITE_SENTRY_DSN,
				},
				supabase: {
					url: VITE_SUPABASE_URL,
					key: VITE_SUPABASE_KEY,
					bucket: VITE_SUPABASE_BUCKET,
				},
				posthog: {
					key: VITE_POSTHOG_KEY,
					host: VITE_POSTHOG_HOST || 'https://eu.i.posthog.com',
				},
				webhook: {
					appointment: VITE_APPOINTMENT_WEBHOOK_URL,
					resume: VITE_RESUME_WEBHOOK_URL,
				},
			}
