import {
	browserTracingIntegration,
	init,
	reactRouterV6BrowserTracingIntegration,
	replayIntegration,
} from '@sentry/react'
import { useEffect } from 'react'
import {
	createRoutesFromChildren,
	matchRoutes,
	useLocation,
	useNavigationType,
} from 'react-router-dom'

import packageJson from '../../package.json'

let isSentryInitialized = false
const { VITE_SENTRY_DSN, NODE_ENV } = import.meta.env

if (VITE_SENTRY_DSN && !isSentryInitialized) {
	isSentryInitialized = true

	init({
		dsn: VITE_SENTRY_DSN,
		environment: NODE_ENV,
		release: packageJson.version,
		integrations: [
			browserTracingIntegration(),
			replayIntegration(),
			reactRouterV6BrowserTracingIntegration({
				useEffect: useEffect,
				useLocation,
				useNavigationType,
				createRoutesFromChildren,
				matchRoutes,
			}),
		],
		debug: NODE_ENV === 'staging',
		tracesSampleRate: 1.0,
		replaysSessionSampleRate: 0.1,
		replaysOnErrorSampleRate: 1.0,
	})
}
