// Enhanced phone regex that accepts:
// - International format with or without + prefix
// - Spaces, dashes, and parentheses for formatting
// - Minimum 8 digits
// - Optional country code
export const PHONE_REGEX = /^\+?[0-9\s-()]{8,}$/

// Name regex that requires:
// - At least 2 characters
// - Only letters, spaces, and basic punctuation
// - No numbers or special characters
export const NAME_REGEX = /^[a-zA-Z\s'-]{2,}$/
